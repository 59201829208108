<template>
  <div>
    <!-- 资讯头图 -->
    <div class="NewsDetails_banner">
      <img src="@/assets/img/News/banner-news.png" />
    </div>
    <div class="NewsDetails">
      <!-- 资讯内容 -->
      <div class="NewsDetails_content" ref="list">
        <!-- 标题与发布时间 -->
        <div class="NewsDetails_contentTitle">
          {{ $route.query.title }}
        </div>
        <div v-html="value"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  created() {
    this.handleContentStyle();
  },
  methods: {
    // 处理内容样式
    handleContentStyle() {
      let { content } = this.$route.query;
      let newCon = "";
      newCon = content.replace(
        /style="font-weight:600; font-size:22px; text-align:left"/g,
        `class="title"`
      );
      newCon = newCon.replace(
        /style="font-weight:600; font-size:24px; text-align:left"/g,
        `class="title"`
      );
      newCon = newCon.replace(/<img/g, `<img class="imgClass"`);
      newCon = newCon.replace(/style="width:60%"/g, "");
      newCon = newCon.replace(/face="宋体"/g, `class="context"`);
      newCon = newCon.replace(/font-family:宋体;/g, "");
      newCon = newCon.replace(/font-size:14.0000pt;/g, "");
      newCon = newCon.replace(/font-family:楷体;/g, "");
      this.value = newCon;
    },
  },
};
</script>

<style scope>
.NewsDetails_banner img {
  width: 100%;
  height: 136px;
  object-fit: cover;
  display: flex;
}
.NewsDetails_content {
  width: 100%;
  padding: 40px 14px;
  box-sizing: border-box;
}
.NewsDetails_contentTitle {
  color: #333333;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  padding: 0 10px;
}
.title {
  color: #808080;
  font-size: 18px;
  text-align: left;
  margin-top: 10px;
  line-height: 28px;
}
.context {
  font-size: 16px;
  color: #808080;
}
.imgClass {
  width: 100%;
  display: flex;
  object-fit: cover;
}
.Section0 {
  font-size: 16px;
  color: #808080 !important;
}
</style>